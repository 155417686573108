import * as React from 'react';
import { currencyMap } from '../../currency';
import { Button } from 'reactstrap';
export function ButtonPayInvoice(_a) {
    var _b = _a.btnColor, btnColor = _b === void 0 ? 'primary' : _b, btnText = _a.btnText, invoice = _a.invoice, loading = _a.loading;
    return (React.createElement(Button, { className: 'button', disabled: loading, color: btnColor, size: 'lg', type: 'submit', block: true }, loading
        ? React.createElement("span", null, "Loading...")
        : btnText
            ? btnText
            : React.createElement(React.Fragment, null,
                React.createElement("span", null,
                    "Pay ",
                    currencyMap[invoice.currency],
                    invoice.owedMoney || invoice.totalCost,
                    React.createElement("small", null, " / inc tax")))));
}
function ButtonStripePayDefault(_a) {
    var btnColor = _a.btnColor, btnText = _a.btnText, invoice = _a.invoice, loading = _a.loading;
    return (React.createElement(ButtonPayInvoice, { btnColor: btnColor, btnText: btnText, invoice: invoice, loading: loading }));
}
export default ButtonStripePayDefault;
